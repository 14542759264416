


export const paymentDetails = {
    gpay:"9643815627@okbizaxis",
    paytm:"9643815627@okbizaxis",
    phonepe:"9643815627@okbizaxis",
    upi:"9643815627@okbizaxis"
}




